import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { Input } from 'components';
import RuleMessage from './components/RuleMessage';

import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';

import regexp from 'constants/regexp';
import { MAX_PASSWORD_LEN, MIN_PASSWORD_LEN } from 'constants/auth';

import './PasswordValidationInput.styles.scss';

const PasswordValidationInput = React.forwardRef(
  ({ form, name, rules, label, dependencies, passwordForMatch, ...props }, inputRef) => {
    const { t } = useTranslation();

    const [isValidLength, setIsValidLength] = useState(false);
    const [hasUpper, setHasUpper] = useState(false);
    const [hasLower, setHasLower] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecial, setHasSpecial] = useState(false);

    const isPasswordMatchExists = typeof passwordForMatch === 'string';
    const [passwordMatch, setPasswordMatch] = useState(!isPasswordMatchExists);

    const password = Form.useWatch(name, form);

    const validatePassword = (value = '', passwordForCheck) => {
      setIsValidLength(value.length >= MIN_PASSWORD_LEN && value.length <= MAX_PASSWORD_LEN);
      setHasUpper(regexp.upperCase.test(value));
      setHasLower(regexp.lowerCase.test(value));
      setHasNumber(regexp.number.test(value));
      setHasSpecial(regexp.specialChar.test(value));

      if (passwordForCheck) {
        setPasswordMatch(value === passwordForCheck);
      }
    };

    useEffect(() => {
      validatePassword(password, passwordForMatch);
    }, [password, passwordForMatch]);

    return (
      <Form.Item
        validateFirst
        name={name}
        rules={rules}
        label={label}
        dependencies={dependencies}
        className="np-password-validation-input__form-item"
      >
        <div className="np-password-validation-input">
          <Input.Password ref={ref => ymProtectInput(ref, inputRef)} {...props} />

          <div className="np-password-validation-input__rules">
            <RuleMessage
              valid={isValidLength}
              message={t('validation.password.length', { min: MIN_PASSWORD_LEN, max: MAX_PASSWORD_LEN })}
            />

            <RuleMessage valid={hasUpper} message="validation.password.upperCase" />
            <RuleMessage valid={hasLower} message="validation.password.lowerCase" />
            <RuleMessage valid={hasNumber} message="validation.password.numbers" />
            <RuleMessage valid={hasSpecial} message="validation.password.specialCharacters" />

            {isPasswordMatchExists && (
              <RuleMessage valid={passwordMatch} message="validation.password.shouldMatchWithPassword" />
            )}
          </div>
        </div>
      </Form.Item>
    );
  },
);

export default PasswordValidationInput;
