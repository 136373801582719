import React from 'react';
import { Helmet } from 'react-helmet-async';

const IconsMarkup = () => {
  return (
    <Helmet>
      <link type="image/x-icon" rel="shortcut icon" href="/neopool_favicon.ico" />
      <link type="image/png" sizes="16x16" rel="icon" href="/img/icons/windows/Square44x44Logo.targetsize-16.png" />
      <link type="image/png" sizes="32x32" rel="icon" href="/img/icons/windows/Square44x44Logo.targetsize-32.png" />
      <link type="image/png" sizes="96x96" rel="icon" href="/img/icons/windows/Square44x44Logo.targetsize-96.png" />
      <link type="image/png" sizes="120x120" rel="icon" href="/img/icons/ios/120.png" />
      <link type="image/png" sizes="192x192" rel="icon" href="/img/icons/android/android-launchericon-192-192.png" />
      <link sizes="57x57" rel="apple-touch-icon" href="/img/icons/ios/57.png" />
      <link sizes="60x60" rel="apple-touch-icon" href="/img/icons/ios/60.png" />
      <link sizes="72x72" rel="apple-touch-icon" href="/img/icons/ios/72.png" />
      <link sizes="76x76" rel="apple-touch-icon" href="/img/icons/ios/76.png" />
      <link sizes="114x114" rel="apple-touch-icon" href="/img/icons/ios/114.png" />
      <link sizes="120x120" rel="apple-touch-icon" href="/img/icons/ios/120.png" />
      <link sizes="144x144" rel="apple-touch-icon" href="/img/icons/ios/144.png" />
      <link sizes="152x152" rel="apple-touch-icon" href="/img/icons/ios/152.png" />
      <link sizes="180x180" rel="apple-touch-icon" href="/img/icons/ios/180.png" />
      <link color="#1E1E1E" rel="mask-icon" href="/neopool_favicon.svg" />
      <meta name="msapplication-TileColor" content="#1E1E1E" />
      <meta name="msapplication-TileImage" content="/img/icons/ios/144.png" />
      <meta name="msapplication-square150x150logo" content="/img/icons/windows/Square150x150Logo.scale-100.png" />
      <meta name="msapplication-wide310x150logo" content="/img/icons/windows/Wide310x150Logo.scale-100.png" />
      <meta name="msapplication-square310x310logo" content="/img/icons/windows/LargeTile.scale-100.png" />
      <meta name="Neopool" content="Neopool" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#1E1E1E" />
    </Helmet>
  );
};

export default IconsMarkup;
