import React from 'react';
import { AutoComplete, Form } from 'antd';
import { Input } from 'components';

import ymProtectInput from 'utils/yandex-metrika/ymProtectInput';

import { HiddenInputType } from './constants/types';

import './FormHiddenInputs.styles.scss';

// Hack for chrome autofill

const DefaultTypes = Object.values(HiddenInputType);
const FormHiddenInputs = ({ types = DefaultTypes }) => (
  <div className="np-form-hidden-inputs">
    {types.includes(HiddenInputType.EMAIL) && (
      <Form.Item noStyle>
        <Input ref={ymProtectInput} autoComplete="email" tabIndex="-1" />
      </Form.Item>
    )}

    {types.includes(HiddenInputType.USERNAME) && (
      <Form.Item noStyle>
        <AutoComplete autoComplete="username" tabIndex="-1" aria-autocomplete="username">
          <Input ref={ymProtectInput} />
        </AutoComplete>
      </Form.Item>
    )}

    {types.includes(HiddenInputType.PASSWORD) && (
      <Form.Item noStyle>
        <Input.Password ref={ymProtectInput} autoComplete="password" tabIndex="-1" />
      </Form.Item>
    )}
  </div>
);

export default FormHiddenInputs;
