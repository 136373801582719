import i18n from 'configs/i18n';
import { createSlice } from '@reduxjs/toolkit';
import { Language } from 'constants/languages';

const defaultLanguage = Language.EN;

const changeDocLanguage = lang => {
  document.documentElement.lang = lang;
};

const getLanguage = () => {
  const allowedLanguages = Object.values(Language);
  const language = allowedLanguages.find(language => i18n.language.includes(language)) || defaultLanguage;

  changeDocLanguage(language);

  return language;
};

export const langSlice = createSlice({
  name: 'lang',
  initialState: {
    language: getLanguage() || defaultLanguage,
  },

  reducers: {
    setLanguage: (state, action) => {
      i18n.changeLanguage(action.payload);
      changeDocLanguage(action.payload);
      state.language = action.payload;
      window.location.reload();
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLanguage } = langSlice.actions;

export default langSlice.reducer;
