import React from 'react';
import { Helmet } from 'react-helmet-async';

const OpenGraphMarkup = ({
  title,
  description,
  image,
  type,
  url,
  vkImage,
  fbImage,
  twitterImage,
  imageWidth,
  imageHeight,
}) => {
  const properties = {
    'og:title': title,
    'og:description': description,
    'og:image': image,
    'og:vk:image': vkImage,
    'og:fb:image': fbImage,
    'og:twitter:image': twitterImage,
    'og:image:width': imageWidth,
    'og:image:height': imageHeight,
    'og:type': type,
    'og:url': url,
  };

  return (
    <Helmet>
      {Object.entries(properties).map(
        ([property, content]) => content && <meta property={property} content={content} key={property} />,
      )}
    </Helmet>
  );
};

export default OpenGraphMarkup;
