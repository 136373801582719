import { SATOSHI_POWER } from 'constants/power';

const roundBtcToSatoshi = value => {
  const satoshiDecPower = Math.pow(10, SATOSHI_POWER);

  const truncedValue = Math.round((value || 0) * satoshiDecPower) / satoshiDecPower;

  return truncedValue?.toFixed(SATOSHI_POWER);
};

export default roundBtcToSatoshi;
