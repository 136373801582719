import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Language } from 'constants/languages';
import { enLng, ruLng } from './langs';

const resources = {
  [Language.EN]: enLng,
  [Language.RU]: ruLng,
};

const supportedLngs = Object.keys(resources);

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(LanguageDetector).use(initReactI18next).init({
  supportedLngs,
  resources,
});

export default i18n;
