import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { Language } from 'constants/languages';
import watcherHasPermission from 'utils/auth/watcherHasPermission';
import { WatcherPermissions } from 'constants/watcher';
import useUserData from 'hooks/user/useUserData';

const JivoChatLangCodes = {
  [Language.RU]: 'aDmAqYq8HR',
  [Language.EN]: 'MjHldvBol4',
};

const JivochatMarkup = () => {
  const { language } = useSelector(state => state.language);
  const { watcher } = useSelector(state => state.watcherAuth);
  const { user } = useUserData();

  const isAdmin = watcherHasPermission({ watcher, requiredPermissions: WatcherPermissions.ADMIN });

  const showChat = user || (watcher && !isAdmin);

  const src = `//code.jivo.ru/widget/${JivoChatLangCodes[language]}`;

  return (
    showChat && (
      <Helmet async>
        <script src={src} async></script>
      </Helmet>
    )
  );
};

export default JivochatMarkup;
